@import '~normalize.css';
@import './scss/colors';
@import '../node_modules/tachyons/css/tachyons.min.css';

html {
  font-size: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $deep-gray;
  font-weight: 400;
}

html,
body,
#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

.subtitle.subtitle {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.navigation-menu-item.navigation-menu-item label {
  font-size: 1rem;
}
