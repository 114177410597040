.title {
  font-weight: 600;
}

.error {
  color: #d72f3f;
}

.info {
  color: #0065ae;
}
