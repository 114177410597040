.form {
  display: flex;
}

.button {
  margin: 2rem 0 0 0.5rem;
}

.card.card {
  padding: 1rem;
}
