.flex {
  display: flex;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
}

.content {
  width: 100%;
  background-color: #fff;
  height: 100%;
  overflow: auto;
}

.header {
  padding: 1rem 0 1rem 2rem;
  background-color: #fff;
}

.header-content {
  display: flex;
  justify-content: space-between;
}

.environments.environments {
  width: 10.5rem;
  margin: 0 1rem 0 0.5rem;
  > div {
    padding: 0;
  }
}

.header-env {
  display: flex;
  align-items: center;
}

.label {
  align-items: center;
  font-weight: 600;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}
