.information {
  text-align: center;
}

.spinner {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.submit {
  justify-content: center;
  display: flex;
}
