@import '../../../scss/colors';

.title {
  font-size: px-to-rem(30);
  line-height: px-to-rem(36);
  letter-spacing: px-to-rem(0.3);
}

.iconSuccess {
  color: $primary-green;
}

.iconError {
  color: $primary-red;
}
