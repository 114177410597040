@import '../../../scss/colors';
@import '../../../scss/mixins';

.link {
  color: $deep-gray;
  text-decoration: none;
}

.card.card {
  display: flex;
  flex: 1 1 0%;
  padding: 1rem;
  border-color: $medium-gray;
  cursor: pointer;

  &:hover {
    background-color: $lightest-gray;
    border-color: $medium-gray;
  }
}

.icon {
  color: $interactivePrimary;
  transition: color 0.2s ease;

  .card:hover & {
    color: $deep-gray;
  }
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.titleSeparator {
  width: px-to-rem(80);
  border-bottom: 2px solid rgba($interactivePrimary, 0.2);
  transition: border-color 0.2s ease;

  .card:hover & {
    border-color: $deep-gray;
  }
}

.description {
  font-size: px-to-rem(14);
  letter-spacing: px-to-rem(0.2);
}
