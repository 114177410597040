@import '../../../scss/colors';
@import '../../../scss/mixins';

.intro {
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.description {
  font-size: px-to-rem(14);
}

.spinner {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.card {
  display: flex;
  border-color: $medium-gray;

  &:hover {
    border-color: $medium-gray;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  > *:not(:first-child) {
    padding-left: px-to-rem(25);
  }

  > *:not(:last-child) {
    padding-right: px-to-rem(25);
  }

  > * {
    flex-grow: 1;
  }
}

.description {
  border-left: 1px $light-gray solid;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.button {
  flex-shrink: 0;
}

.button-text {
  font-size: px-to-rem(14);
}

.small-button-text {
  font-size: px-to-rem(12);
  line-height: px-to-rem(12);
}
