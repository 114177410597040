@import '../../../scss/colors';

.th {
  border-bottom: 2px $deep-gray solid;
  text-align: left;
  height: 4rem;
}

.td {
  border-bottom: 1px $light-gray solid;
}

.td,
.th {
  padding: 1rem 0.5rem;
}

tbody > .tr:last-child > td {
  border-bottom: 2px $deep-gray solid;
}

.table {
  border-spacing: 0;
  width: 100%;
  border-top: 1px solid $light-gray;
}
